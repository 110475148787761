import anime from 'animejs/lib/anime.es.js';
import { parseQueryString } from './queryString';

export function getDefaultBannerOffset() {
    // return document.querySelector('.banner').getBoundingClientRect().height
    return 0;
}

export function animeScroll(hash, offset = 0, focusAfter = null, parent = document) {
    /**
     * We try to check first if we're scrolling inside the same page
     * when some systems enter URLs absolutely even when adding anchors only
     */
    let curHash = hash;
    if (curHash.indexOf('#') === -1) {
        const currentURL = window.location.origin + window.location.pathname + window.location.search;
        try {
            const hashURL = new URL(curHash);
            if (hashURL.origin + hashURL.pathname + hashURL.search !== currentURL) {
                return false;
            }
            curHash = hashURL.hash;
        } catch (e) {
        // do nothing
        }
    }

    const el = parent.querySelector(hash);
    if (el) {
        const scrollOffset = window.scrollY + el.getBoundingClientRect().top - offset;

        // calculate duration against distance
        // to make speed always the same
        const diff = Math.abs(window.scrollY - scrollOffset);
        const timeSpeed = diff * 0.5;

        if (window.scrollAnim) {
            window.scrollAnim.pause();
        }

        window.scrollAnim = anime({
            targets: 'html,body',
            scrollTop: scrollOffset,
            duration: timeSpeed,
            easing: 'cubicBezier(.56,.01,.28,1)',
            complete() {
                let loc = `#target=${encodeURIComponent(curHash)}&offset=${offset}`;
                if (focusAfter) {
                    if (parent.querySelector(focusAfter)) {
                        parent.querySelector(focusAfter).focus();
                        loc += `&focusAfter=${focusAfter}`;
                    }
                }

                window.location.hash = loc;
                el.classList.add('targeted-once');
            },
        });

        return true;
    }

    return false;
}

export function scrollToElementTarget(hash) {
    const offset = getDefaultBannerOffset();
    return animeScroll(hash, offset);
}

export function scrollToHrefTarget(hash) {
    if (typeof hash !== 'undefined' && hash != '' && hash.indexOf('#target=') != -1) {
        const params = parseQueryString(hash);
        if (typeof params.target !== 'undefined') {
            params.offset = params.offset || getDefaultBannerOffset();
            params.focusAfter = params.focusAfter || null;
            return animeScroll(decodeURIComponent(params.target), params.offset, params.focusAfter);
        }
    }
    return false;
}

window.addEventListener('wheel', () => {
    if (window.scrollAnim) {
        window.scrollAnim.pause();
    }
});

import Theme from '@swup/theme';

export default class MmaqTheme extends Theme {
    name = 'MmaqTheme';

    defaults = {
        duration: 1000,
    };

    constructor(options) {
        super();

        this.options = {
            ...this.defaults,
            ...options,
        };
    }

    mount() {
        this.swup.hooks.on('animation:out:start', (visit) => {
            // console.log(arguments, visit);
            // if(this.swup.transition && this.swup.transition.custom == 'fade') {
            // let pageEl = document.querySelector('div.page')
            // let bgColor = window.getComputedStyle(pageEl).backgroundColor
            // document.querySelector('.swup-transition-overlay-left').style.setProperty('--swup-overlay-bg', bgColor)
            // document.querySelector('.swup-transition-overlay-right').style.setProperty('--swup-overlay-bg', bgColor)
            // }
        });

        this.swup.hooks.on('visit:end', (visit) => {
            // if(this.swup.transition && this.swup.transition.custom == 'fade') {
            // document.querySelector('.swup-transition-overlay-left').style.setProperty('--swup-overlay-bg', '')
            // document.querySelector('.swup-transition-overlay-right').style.setProperty('--swup-overlay-bg', '')
            // }
        });

        this.applyHTML(`
            <div class="swup-transition-overlay swup-transition-overlay-left"
            style="transition-duration: ${this.options.duration}ms"></div>
            <div class="swup-transition-overlay swup-transition-overlay-right"
            style="transition-duration: ${this.options.duration}ms"></div>
            <div class="swup-transition-overlay swup-transition-overlay-center"
            style="transition-duration: ${this.options.duration}ms"></div>
        `);
    }
}

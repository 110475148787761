// import {domReady} from '@roots/sage/client';
import './initSwup';
import 'simplebar';
import './components.js';
// import './patch-acf-forms.js';
import Alpine from 'alpinejs';
import { dispatch } from 'alpinejs/src/utils/dispatch';
import { isRobot } from './isRobot';
import isTouchDevice from './isTouchDevice';

/**
 * app.main
 */
const main = async (err) => {
    if (err) {
        // handle hmr errors
        console.error(err);
    }

    /**
     * Init alpine
     */
    window.Alpine = Alpine;
    Alpine.start();
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
// domReady(main);
document.addEventListener('DOMContentLoaded', () => {
    main();
});
window.addEventListener('load', () => {
    // fake initial swup
    document.documentElement.classList.add('init-rendering');
    window.setTimeout(() => {
        const fake = document.querySelector('#fake-swup');
        if (fake) {
            fake.remove();
        }
        document.documentElement.classList.remove('init-rendering', 'init-animating', 'init-changing');
    }, 1500);
});

/**
 * Google maps: Callback function when Google Maps API finished loading
 * (can be delayed by script blockers and gdpr compliance plugins)
 */
// window.initMaps = () => {
//     dispatch(window, 'mapsready');
// }

if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
